/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import Container from '@beelineloans/cx-library/src/components/layout/ContainerV2';
import UpdateAt from '@beelineloans/cx-library/src/components/layout/UpdateAt';
import { H2, H3, Paragraph, Sub } from '@beelineloans/cx-library/src/components/typography';
import { ButtonText } from '../../../../../shared/GatsbyButton';

const Table = styled.table`
  border-collapse: collapse;
`;
const THead = styled.thead``;
const TBody = styled.tbody``;
const Row = styled.tr``;
const Cell = styled.td`
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
`;
const HeadCell = styled.th`
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
`;
const Title = styled(H3)`
  padding-top: 40px;
`;
const Tab = styled.span`
  padding-top: 20px;
  padding-left: 40px;
  display: block;
`;
const Bordered = styled.div`
  padding: 0 20px;
  border: 1px solid ${(props) => props.theme.colours.border.default};
`;

const PrivacyAndSecurity = () => (
  <NavOnlyTemplate
    headerProps={{
      hideButton: true
    }}
  >
    <SEO path={CONST.LINKS.MAIN.LEGAL.DEFAULT} title="Privacy and Security Notice (California Residents)" description="Beeline's privacy and security policies." />
    <UpdateAt date="April 2024" />
    <Container>
      <H2>Privacy and Security Notice (California Residents)</H2>
      <Paragraph>
        This Privacy and Security Notice (California Residents) supplements the information contained in Beeline&apos;s&nbsp;
        <ButtonText to={CONST.LINKS.MAIN.LEGAL.DEFAULT} newWindow selected>
          Privacy and Security Notice
        </ButtonText>{' '}
        and applies solely to all visitors, users, and others who reside in the State of California (“consumers” or “you”). Effective January 1, 2020, the California Consumer
        Privacy Act (CCPA) allows California residents, upon a verifiable consumer request, to request that a business that collects consumers&apos; personal information to give
        consumers access, in a portable and (if technically feasible) readily usable form, to the specific pieces and categories of personal information that the business has
        collected about the consumer, the categories of sources for that information, the business or commercial purposes for collecting the information, and the categories of
        third parties with which the information was shared. California residents also have the right to submit a request for deletion of information under certain circumstances.
        Consistent with California law, if you choose to exercise your rights, we won&apos;t charge you different prices or provide different quality of services unless those
        differences are related to your information.
      </Paragraph>

      <Title>INFORMATION WE COLLECT</Title>
      <Paragraph>
        We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked to a particular consumer or
        device (also known as “Personal Information”).
      </Paragraph>
      <Paragraph>In particular, we may collect the following categories of Personal Information about you within the last twelve (12) months:</Paragraph>

      <Table>
        <THead>
          <Row>
            <HeadCell>Category</HeadCell>
            <HeadCell>Examples</HeadCell>
            <HeadCell>Collected</HeadCell>
          </Row>
        </THead>
        <TBody>
          <Row>
            <Cell>Identifiers</Cell>
            <Cell>
              A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security number,
              driver&apos;s license number, passport number, or other similar identifiers.
            </Cell>
            <Cell>Yes</Cell>
          </Row>
          <Row>
            <Cell>Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</Cell>
            <Cell>
              A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver&apos;s license or state
              identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any
              other financial information, medical information, or health insurance information. Some Personal Information included in this category may overlap with other
              categories.
            </Cell>
            <Cell>Yes</Cell>
          </Row>
          <Row>
            <Cell>Protected Classifications</Cell>
            <Cell>
              Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex
              (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status,
              genetic information (including familial genetic information).
            </Cell>
            <Cell>Yes</Cell>
          </Row>
          <Row>
            <Cell>Commercial Information</Cell>
            <Cell>Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</Cell>
            <Cell>Yes</Cell>
          </Row>
          <Row>
            <Cell>Biometric Information</Cell>
            <Cell>
              Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information,
              such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.
            </Cell>
            <Cell>No</Cell>
          </Row>
          <Row>
            <Cell>Internet, Browsing, or Other Network Information</Cell>
            <Cell>Browsing history, search history, information on a consumer&apos;s interaction with a website, application, or advertisement.</Cell>
            <Cell>Yes</Cell>
          </Row>
          <Row>
            <Cell>Geolocation Data</Cell>
            <Cell>Physical location or movements.</Cell>
            <Cell>Yes</Cell>
          </Row>
          <Row>
            <Cell>Sensory Data</Cell>
            <Cell>Audio, electronic, visual, thermal, olfactory, or similar information.</Cell>
            <Cell>Yes</Cell>
          </Row>
          <Row>
            <Cell>Professional or Employment Information</Cell>
            <Cell>Current or past job history or performance evaluations.</Cell>
            <Cell>Yes</Cell>
          </Row>
          <Row>
            <Cell>Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99))</Cell>
            <Cell>
              Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists,
              student schedules, student identification codes, student financial information, or student disciplinary records.
            </Cell>
            <Cell>No</Cell>
          </Row>
          <Row>
            <Cell>Inferences drawn from other personal information</Cell>
            <Cell>
              Profile reflecting a person&apos;s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.
            </Cell>
            <Cell>No</Cell>
          </Row>
        </TBody>
      </Table>

      <Paragraph>Personal information does not include:</Paragraph>

      <ul>
        <li>Publicly available information from government agencies,</li>
        <li>Deidentified or aggregated consumer information, or</li>
        <li>
          Information excluded from the scope of the California Consumer Protection Act (“CCPA”), including, but not limited to, Personal Information covered by certain
          sector-specific privacy laws, including the Fair Credit Reporting Act (FCRA), Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA).
        </li>
      </ul>

      <Title>SOURCES OF INFORMATION</Title>

      <Paragraph>We may obtain the categories of Personal Information listed above from the following categories of sources:</Paragraph>

      <ul>
        <li>
          <Sub>Directly from you.</Sub> For example, through use of our websites (as described below in How and Why We Use Web Technologies), when obtaining a product or services
          from us, when signing up for communications from us, through telephone or web-based surveys, online chat, customer service correspondence or general feedback, or when you
          apply for a job with us.
        </li>
        <li>
          <Sub>Indirectly from you.</Sub> For example, from observing your actions on our websites.
        </li>
        <li>
          <Sub>From third parties.</Sub> For example, information provided by third parties such as lead generators, data brokers and credit bureaus.
        </li>
        <li>
          <Sub>Through cookies, tracking tools, and other automated technologies.</Sub> As described in How and Why We Use Web Technologies.
        </li>
      </ul>

      <Title>HOW AND WHY WE USE WEB TECHNOLOGIES</Title>

      <Paragraph>Here is how and why we use some common web technologies to help manage our websites:</Paragraph>

      <Paragraph>
        <Sub>Cookies:</Sub> A &quot;cookie&quot; is a small piece of information that our website may provide to your browser while you are at our sites. We may use web-based
        tools, like cookies to see which areas of our site users prefer (e.g., based on the number of visits to those areas) which will help us better market to individual
        consumers. This is done through a tracking utility that allows us to reconstruct activity from a session or by a user, for troubleshooting and issue resolution purposes.
        Beeline may also employ service providers to help us collect and understand our website usage data. These service providers may also use cookies. We do not process
        information through cookies to identify a particular individual. Cookies are stored on your device, and you have full access and control over their use. You may deactivate
        or restrict the transmission of cookies by changing the settings of your web browser. Cookies that are already stored may be deleted at any time. If you visit our website
        with cookies deactivated, you may not be able to use all the functions of our site fully. When periodic surveys are presented to website visitors, cookies are used to
        prevent issuing multiple invitations to the same individual.
      </Paragraph>

      <Paragraph>
        If you are a registered member on the Beeline website, you will receive a cookie when you visit the website or log in. This cookie is maintained by your browser and
        contains your identifier. This cookie is also used to authenticate your identity and provide you with access to areas of our website that are limited to registered members,
        such as those that allow you to view and manage your personal profile.
      </Paragraph>

      <Paragraph>
        Additionally, Beeline service providers that serve ads on affiliate and/or advertiser websites may assign different cookies and small graphical images called single-pixel
        GIFs or web beacons, to your browser to track the effectiveness of our advertising on other websites and your involvement with Beeline. We may also employ service providers
        who may assign cookies or web beacons to your browser to assist us in collecting website usage data such as your IP address, session ID, URL and demographic information
        such as your zip code. The collection of data may include personally identifiable information. We do not track URLs that you type into your browser, nor do we track you
        across the internet once you leave our site.
      </Paragraph>

      <Paragraph>
        Your web browser may be set to accept cookies automatically, but can be changed to decline them. If you wish to remove cookies provided by Beeline, please check your
        browser&apos;s settings and its Help section for instructions for removing cookies from your browser. If you decline to accept cookies, then you may be required to
        re-register each time you download or request materials from the website.
      </Paragraph>

      <Paragraph>
        We may use internal or third-party advertising companies to collect non-personally identifiable information for online behavioral advertising to provide targeted display
        advertisements, through participating publishers and advertisers, that are likely to be of greater interest to you. Such information may include non-personally identifiable
        information (e.g., click stream information, browser type, time and date, subject of advertisements clicked or scrolled over) through the use of cookies and other
        technology.
      </Paragraph>

      <Paragraph>
        You can change your cookie settings in your internet browser and use preferences or settings on your device to manage your privacy controls. However, if you disable cookies
        or other tools, some of the features of the website may not function correctly. For more information, see&nbsp;
        <ButtonText to="https://www.allaboutcookies.org/" newWindow selected>
          https://www.allaboutcookies.org/
        </ButtonText>
        &nbsp; and learn how to disable these tools by opting-out of&nbsp;
        <ButtonText to="http://optout.aboutads.info/?c=2&lang=EN" newWindow selected>
          third-party cookies
        </ButtonText>
        &nbsp;and&nbsp;
        <ButtonText to="https://youradchoices.com/appchoices" newWindow selected>
          mobile device ID practices
        </ButtonText>
        &nbsp;for targeted advertising.
      </Paragraph>

      <Paragraph>
        <Sub>Website usage data:</Sub> Our website tracks usage data, including, for example, your IP address, your browser type and version, which pages you view, which page, if
        any, linked you to our site, and which link, if any, you follow off of our site. We use this data in the aggregate and on an individual level to better understand website
        activity to improve our site offerings, to reconstruct activity from a session or by a user, for troubleshooting and issue resolution purposes. We may also use this data to
        provide you a more personalized website experience, assistance with technical support questions, to send you special offers, product and service updates, or other
        promotional materials that are relevant and tailored to your interests.
      </Paragraph>

      <Paragraph>
        If you do not want to receive these offers or promotions, simply indicate your contact preferences during the registration process, within any future communications to us
        or by sending an email to&nbsp;
        <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.OPTOUT}`} selected>
          {CONST.LINKS.EXTERNAL.EMAIL.OPTOUT}
        </ButtonText>
        . We do not share your information with outside companies for their promotional use, however we do use certain functionality from Google and others for data analytics and
        advertising us and our products on the internet.
      </Paragraph>

      <Paragraph>
        We have implemented the following features with Google: Tag Manager, Analytics, Optimize & Ads. For more information about Google Analytics and how to opt out of their data
        collection, visit the&nbsp;
        <ButtonText to="https://tools.google.com/dlpage/gaoptout/" newWindow selected>
          Google Analytics Opt-Out Add On
        </ButtonText>
        &nbsp; page.
      </Paragraph>

      <Title>SOCIAL MEDIA SITES AND MARKETING SECURITY PRACTICES</Title>

      <Paragraph>
        We use social media to connect with and market to the public, our customers, and potential customers of the Beeline family. Your activity on third party websites is
        governed by the security and privacy policies of each third-party website. Users of third-party websites often share information with the general public, user community,
        and/or the third-party vendors operating these websites, which may use this information in a variety of ways. Consequently, you should review the privacy policies of the
        third-party website before using it and ensure that you understand how this information may be used. You may be able to adjust privacy settings on your accounts on any
        third-party website or application to match your preferences. The information posted on or directed at us through social media is generally available to the public. To
        protect your privacy, do not include information you want to keep private or any other sensitive personal information in your social media activity, comments or responses.
        This information may be archived independently on, and retention of such information is governed by the third-party website. Additionally, we may use third party marketing
        platforms and other data analytics tools to analyze your preferences and certain transaction details. In order to maximize these capabilities, we may store some data about
        you on those platforms.
      </Paragraph>

      <Title>USE OF PERSONAL INFORMATION</Title>

      <Paragraph>We may use or disclose the personal information we collect for one or more of the following business purposes or commercial purposes:</Paragraph>

      <ul>
        <li>To provide services to you;</li>
        <li>To maintain your account with us; </li>
        <li>To report to credit bureaus;</li>
        <li>To provide, support, personalize, and develop our Website;</li>
        <li>To detect fraud;</li>
        <li>To provide you with support and to respond to your inquiries, including to investigate and address your concerns and monitor and improve our responses;</li>
        <li>To our affiliates to provide services to you (for example, escrow or title services);</li>
        <li>To third parties to market to you (unless you have opted out);</li>
        <li>For our affiliates to market to you (unless you have opted out);</li>
        <li>For our affiliates business purposes;</li>
        <li>To help maintain the safety, security, and integrity of our Website, services, databases and other technology assets, and business;</li>
        <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations;</li>
        <li>For auditing purposes;</li>
        <li>For quality control purposes; and/or</li>
        <li>As described to you when collecting your personal information or as otherwise set forth in the CCPA.</li>
      </ul>

      <Paragraph>
        When we use your information for a business purpose, it means such use is reasonably necessary and proportionate to achieve an operational purpose of Beeline. When we use
        your information for a commercial purpose, it means such use is intended to advance Beeline&apos;s business interests, such as by inducing you to buy one of our products or
        services.
      </Paragraph>
      <Paragraph>
        We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes
        without providing you notice.
      </Paragraph>
      <Paragraph>
        We give you the opportunity to control the use of your Personal Information for purposes outside of completing and servicing your transaction. For example, occasionally, we
        may use your contact information to send you promotional communications about Beeline products, including those of our affiliates. We may also share your contact
        information with our third-party partners to send you promotional communications about their products that may be of interest based on your particular loan file attributes
        (such as Newzip who assists customers with finding a realtor for purchase transactions). These communications may include email, telephone, texts and direct mail. If you
        don&apos;t wish to receive these, you can tell us your contact preferences during the information collection process, or you can call us at 1-800-550-6602 or send an email
        to&nbsp;
        <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.OPTOUT}`} selected>
          {CONST.LINKS.EXTERNAL.EMAIL.OPTOUT}
        </ButtonText>
        .
      </Paragraph>
      <Paragraph>
        We use Google for certain marketing purposes. If you wish to opt out of Google&apos;s use of your information for marketing purposes, you may opt out through this
        link&nbsp;
        <ButtonText to="https://tools.google.com/dlpage/gaoptout/" newWindow selected>
          https://tools.google.com/dlpage/gaoptout/
        </ButtonText>
        &nbsp; and follow the instructions on the website.
      </Paragraph>
      <Paragraph>
        Additionally, you can manage your ad preferences with Google through the following link&nbsp;
        <ButtonText to="https://tools.google.com/dlpage/gaoptout/" newWindow selected>
          https://adssettings.google.com/
        </ButtonText>
      </Paragraph>

      <Title>SHARING OF INFORMATION</Title>

      <Paragraph>We may share your Personal Information by disclosing it to a third party for a business purpose.</Paragraph>
      <Paragraph>
        These providers allow us to provide you with a better experience by bypassing the old-fashioned document gathering - and they are listed here: Amazon Web Services, Beeline
        Title Holdings, Inc. and its subsidiaries, Black Knight Data Analytics, LLC, ComplianceEase/SitusAMC, Docutech, Equifax/TWN, Estated, First American, FNMA, Freddie Mac,
        Google, LenderPrice, LexisNexis, Lodestar, MERSCORP Holdings, Inc., Mortgage Electronic Registration System, Inc., Ncontracts/QuestSoft, Nimble Title Holdings, LLC and its
        subsidiaries, LLC, Plaid, Inc., Qualia, Relcu, ServiceLink, Stellar Innovative Solutions Corp., ValueLink, Unum ID Inc. (“Verified Inc.“), Vesta Innovations, Inc. and
        Xactus, LLC. Their respective privacy policies can be found on their websites. You accept that we may share your information with these data partners so they can give us
        the insight we need into things like your finances, property, employment, etc. that are needed for your loan application. The information we share may include, but is not
        limited to, your full name, birth date, address, telephone number and social security number (only where necessary). In the event you are directed to or use a third party
        company’s website, these third party websites have their own privacy policies, including cookies, and we urge you to review them. They will govern the use of personal
        information you submit or are collected by cookies whilst visiting these websites. We do not accept any responsibility or liability for the privacy practices of such third
        party websites and your use of such websites is at your own risk.
      </Paragraph>
      <Paragraph>
        We only make these business purpose disclosures with third parties who are bound by confidentiality obligations or as otherwise provided for in this Notice. In the
        preceding twelve (12) months, we may have disclosed your Personal Information for a business purpose to the categories of third parties indicated in the chart below:
      </Paragraph>

      <Table>
        <THead>
          <Row>
            <HeadCell>Personal Information Category</HeadCell>
            <HeadCell>Business Purpose Disclosures</HeadCell>
            <HeadCell>Sales</HeadCell>
          </Row>
        </THead>
        <TBody>
          <Row>
            <Cell>Identifiers</Cell>
            <Cell>
              <ul>
                <li>our subsidiaries and affiliates, which includes but is not limited to our title company affiliates</li>
                <li>our service providers</li>
                <li>our data providers</li>
                <li>law enforcement or other governmental authorities</li>
                <li>underwriters</li>
                <li>other parties for whom you have given us written authorization to disclose</li>
              </ul>
            </Cell>
            <Cell>Yes, to third party partners for realty-related services</Cell>
          </Row>
          <Row>
            <Cell>Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</Cell>
            <Cell>
              <ul>
                <li>our subsidiaries and affiliates, which includes but is not limited to our title company affiliates</li>
                <li>our service providers</li>
                <li>our data providers</li>
                <li>law enforcement or other governmental authorities</li>
                <li>underwriters</li>
                <li>other parties for whom you have given us written authorization to disclose</li>
              </ul>
            </Cell>
            <Cell>Yes, to third party partners for realty-related services</Cell>
          </Row>
          <Row>
            <Cell>Protected Classifications</Cell>
            <Cell>
              <ul>
                <li>our subsidiaries and affiliates, which includes but is not limited to our title company affiliates</li>
                <li>our service providers</li>
                <li>our data providers</li>
                <li>law enforcement or other governmental authorities</li>
                <li>underwriters</li>
                <li>other parties for whom you have given us written authorization to disclose</li>
              </ul>
            </Cell>
            <Cell>No</Cell>
          </Row>
          <Row>
            <Cell>Commercial Information</Cell>
            <Cell>
              <ul>
                <li>our subsidiaries and affiliates, which includes but is not limited to our title company affiliates</li>
                <li>our service providers</li>
                <li>our data providers</li>
                <li>law enforcement or other governmental authorities</li>
                <li>underwriters</li>
                <li>other parties for whom you have given us written authorization to disclose</li>
              </ul>
            </Cell>
            <Cell>No</Cell>
          </Row>
          <Row>
            <Cell>Biometric Information</Cell>
            <Cell>n/a</Cell>
            <Cell>n/a</Cell>
          </Row>
          <Row>
            <Cell>Internet, Browsing, or Other Network Information</Cell>
            <Cell>
              <ul>
                <li>our subsidiaries and affiliates, which includes but is not limited to our title company affiliates</li>
                <li>our service providers</li>
                <li>our data providers</li>
                <li>law enforcement or other governmental authorities</li>
                <li>underwriters</li>
                <li>other parties for whom you have given us written authorization to disclose</li>
              </ul>
            </Cell>
            <Cell>No</Cell>
          </Row>
          <Row>
            <Cell>Geolocation Data</Cell>
            <Cell>n/a</Cell>
            <Cell>n/a</Cell>
          </Row>
          <Row>
            <Cell>Sensory Data</Cell>
            <Cell>our service providers</Cell>
            <Cell>No</Cell>
          </Row>
          <Row>
            <Cell>Professional or Employment Information</Cell>
            <Cell>
              <ul>
                <li>our subsidiaries and affiliates, which includes but is not limited to our title company affiliates</li>
                <li>our service providers</li>
                <li>our data providers</li>
                <li>law enforcement or other governmental authorities</li>
                <li>underwriters</li>
                <li>other parties for whom you have given us written authorization to disclose</li>
              </ul>
            </Cell>
            <Cell>No</Cell>
          </Row>
          <Row>
            <Cell>Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99))</Cell>
            <Cell>n/a</Cell>
            <Cell>n/a</Cell>
          </Row>
          <Row>
            <Cell>Inferences drawn from other personal information</Cell>
            <Cell>n/a</Cell>
            <Cell>n/a</Cell>
          </Row>
        </TBody>
      </Table>

      <Title>HOW WE PROTECT YOUR INFORMATION</Title>

      <Paragraph>
        We maintain physical, electronic and procedural safeguards to protect your Personal Information. Encryption technology is used when loan applications and other non-public
        Personal Information is transmitted over the internet. We restrict access to Personal Information to those employees who need to know that information to provide our
        services to you. Our employees are bound by a Confidentiality Agreement requiring confidential treatment of all such information. We train our employees in security
        procedures, and we conduct audits to verify compliance with these procedures.
      </Paragraph>

      <Paragraph>
        Additionally, we use industry-recognized security safeguards to protect your information from loss, misuse or unauthorized alteration. We use SSL transaction protocol for
        all email communication as well as strong encryption products and use AWS hosting services.
      </Paragraph>

      <Title>YOUR CONSUMER RIGHTS</Title>

      <Paragraph>
        You may access all information we&apos;ve collected and retained about you for purposes of corrective, amending, downloading, or deleting such information. Access can be
        obtained by logging into your account, filling out a&nbsp;
        <ButtonText to={CONST.LINKS.EXTERNAL.LEGAL.PRIVACY} newWindow selected>
          Privacy Request
        </ButtonText>
        , or contacting us at&nbsp;
        <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}`} selected>
          {CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}
        </ButtonText>
        .
      </Paragraph>

      <Paragraph>
        <b>
          To protect your privacy and security, we may take reasonable steps to help verify your identity before granting access, making corrections, providing you with
          information, or deleting information.
        </b>
      </Paragraph>

      <ul>
        <li>
          <Sub>Access and Correction</Sub>
          <br />
          <Paragraph>
            The easiest way to access and correct the Personal Information we have collected about you is to contact your Loan Guide. In the alternative, you may fill out a&nbsp;
            <ButtonText to={CONST.LINKS.EXTERNAL.LEGAL.PRIVACY} newWindow selected>
              Privacy Request
            </ButtonText>
            , or contact us at&nbsp;
            <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}`} selected>
              {CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}
            </ButtonText>
            .
          </Paragraph>
        </li>
        <li>
          <Sub>Right to Request Disclosure</Sub>
          <br />
          <Paragraph>You have the right to request that we disclose the following to you:</Paragraph>
          <ul>
            <li>The categories of personal information we have collected about you;</li>
            <li>The categories of sources from which the personal information is collected;</li>
            <li>The business or commercial purpose for collecting or selling personal information;</li>
            <li>The categories of third parties with whom we share personal information; and</li>
            <li>The specific pieces of personal information we have collected about you.</li>
          </ul>
          <Paragraph>
            To request such a disclosure, please fill out a&nbsp;
            <ButtonText to={CONST.LINKS.EXTERNAL.LEGAL.PRIVACY} newWindow selected>
              Privacy Request
            </ButtonText>
            , or contact us at&nbsp;
            <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}`} selected>
              {CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}
            </ButtonText>
            .
          </Paragraph>
        </li>
        <li>
          <Sub>Right to Request Deletion</Sub>
          <br />
          <Paragraph>
            You have the right to request that we delete any personal information about the you which we have collected from you. To make such a request, please fill out a&nbsp;
            <ButtonText to={CONST.LINKS.EXTERNAL.LEGAL.PRIVACY} newWindow selected>
              Privacy Request
            </ButtonText>
            , or contact us at&nbsp;
            <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}`} selected>
              {CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}
            </ButtonText>
            .
          </Paragraph>
        </li>
        <li>
          <Sub>California Shine the Light Disclosure</Sub>
          <br />
          <Paragraph>
            YUnder California&apos;s “Shine the Light” law, California residents who provide personal information to the website may request certain information regarding our
            disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please fill out a&nbsp;
            <ButtonText to={CONST.LINKS.EXTERNAL.LEGAL.PRIVACY} newWindow selected>
              Privacy Request
            </ButtonText>
            , or contact us at&nbsp;
            <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}`} selected>
              {CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}
            </ButtonText>
            .
          </Paragraph>
        </li>
        <li>
          <Sub>RIGHT TO OPT-OUT OF SALE OF PERSONAL INFORMATION</Sub>
          <br />
          <Paragraph>
            You have the right, at any time, to direct us not to sell your information. You may do so by filling out the&nbsp;
            <ButtonText to={CONST.LINKS.EXTERNAL.LEGAL.PRIVACY} newWindow selected>
              Do Not Sell My Personal Information
            </ButtonText>
            &nbsp;form on the Website.
          </Paragraph>
        </li>
      </ul>

      <Paragraph>
        <Sub>Exercising Your Rights to Know or Delete</Sub>
        <br />
        To exercise your rights to know or delete described above, please submit a request by either:
        <br />
        <Tab>
          Calling us at&nbsp;
          <ButtonText to={`tel:${CONST.LINKS.EXTERNAL.PHONE.DEFAULT}`} selected>
            {CONST.LINKS.EXTERNAL.PHONE.DASHED_INT}
          </ButtonText>
          <br />
          Emailing us at&nbsp;
          <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}`} selected>
            {CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}
          </ButtonText>{' '}
          <br />
          Completing the Privacy Request Form located at&nbsp;
          <ButtonText to={CONST.LINKS.EXTERNAL.LEGAL.PRIVACY} newWindow selected>
            {CONST.LINKS.EXTERNAL.LEGAL.PRIVACY}
          </ButtonText>
        </Tab>
      </Paragraph>

      <Paragraph>
        <Sub>Verification and Processing of Requests</Sub>
        <br />
        Upon receiving your request, we will verify your identity using your Personal Information. Then we will process your request. You may only submit a request to know twice
        within a twelve (12) month period. In order to verify your request, your request must provide sufficient information that allows us to reasonably verify that you are the
        person about whom we collected Personal Information or are an authorized representative of such person and describe your request with sufficient detail that allows us to
        properly understand, evaluate and respond to it. The request should include the date of the request, your name, address and telephone number, the type of request (request
        to know - categories of information, request to know - specific information, request to delete information, etc.) and if applicable, your loan number.
      </Paragraph>

      <Paragraph>
        We will be unable to respond to your request or provide you with Personal Information if we cannot verify your identity or authority to make the request and confirm the
        Personal Information relates to you. We will only use Personal Information provided in a verifiable consumer request to verify the requestor&apos;s identity or authority to
        make the request. Making a verifiable consumer request does not require you to create an account with us.
      </Paragraph>

      <Paragraph>
        <Sub>Authorized Agents</Sub>
        <br />
        You may designate an authorized agent to make a request on your behalf. You may make such a designation by providing the agent with written permission to act on your
        behalf. As permitted by law, we may require you to verify your own identity in response to a request even if you choose to use an authorized agent. We may require
        authorized agents to provide verification that they act on your behalf, as permitted by applicable law.
      </Paragraph>

      <Paragraph>
        <Sub>Timing of Response</Sub>
        <br />
        For a Request to Delete or Request to Know Categories, we will strive to fulfill these requests within 45 days from the date we receive your request or as otherwise
        required by law. If additional time is needed to complete any request, we will notify you that additional time is needed, tell you the reason that we need additional time,
        and tell you when you can expect your request to be completed.
      </Paragraph>

      <Paragraph>
        For a Request to Know Specific Pieces Report, we may need an additional 45 day extension to respond to your request, as these requests will be burdensome and we will have
        to work with multiple systems and service providers in order to fulfill your request. Therefore, you can expect to receive your Specific Pieces Report within 90 days of
        your request.
      </Paragraph>

      <Paragraph>
        <Sub>Right to Non-Discrimination for the Exercise of Your Privacy Rights</Sub>
        <br />
        You have the right to be protected from discrimination for exercising your CCPA rights. We cannot and will not discriminate against you for exercising your rights under the
        statute, including by: (A) denying you goods or services; (B) charging you different prices or rates for goods or services, including through the use of discounts or other
        benefits or imposing penalties; (C) providing you with a different level or quality of goods or services; or (D) suggesting that you will receive a different price, rate,
        level, or quality of goods or services. Nothing prohibits a business from charging a different price or providing a different level or quality of service if the difference
        is reasonably related to the value provided to the consumer by the consumer&apos;s data.
      </Paragraph>

      <Title>SALE OF INFORMATION</Title>

      <Paragraph>
        Yes. <b>Please take notice that your information may be sold to a Third Party</b>. Beeline generates revenue by connecting our Third-Party partners with consumers who have
        expressed interest in their products and services. In doing so, we pass on the personal information of the interested consumers. Under some laws and regulations, could be
        considered the “sale” of personal information. The categories of information that may be sold are:
        <ul>
          <li>Information you provide to us (Identifiers and Personal Information as defined above)</li>
        </ul>
      </Paragraph>

      <Bordered>
        <Paragraph>
          <b>RIGHT TO OPT-OUT OF SALE OF PERSONAL INFORMATION</b>
          <br />
          <br />
          You have the right, at any time, to direct Company not to sell your information. You may do so by filling out the&nbsp;
          <ButtonText to={CONST.LINKS.EXTERNAL.LEGAL.PRIVACY} newWindow selected>
            Do Not Sell My Personal Information
          </ButtonText>
          &nbsp;form on the Website.
        </Paragraph>
      </Bordered>

      <Paragraph>
        Once you make an opt-out request, we will wait at least twelve (12) months before asking you to reauthorize personal information sales. However, you may change your mind
        and opt back in to personal information sales at any time by:
        <br />
        <Tab>
          Calling us at&nbsp;
          <ButtonText to={`tel:${CONST.LINKS.EXTERNAL.PHONE.DEFAULT}`} selected>
            {CONST.LINKS.EXTERNAL.PHONE.DASHED_INT}
          </ButtonText>
          <br />
          Emailing us at&nbsp;
          <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}`} selected>
            {CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}
          </ButtonText>
        </Tab>
      </Paragraph>

      <Paragraph>
        You do not need to create an account with us to exercise your opt-out rights. We will only use personal information provided in an opt-out request to review and comply with
        the request.
      </Paragraph>

      <Title>INFORMATION FROM CHILDREN</Title>

      <Paragraph>
        Because we&apos;re in the business of finance, our websites aren&apos;t for anyone under 18. So, we don&apos;t knowingly attempt to solicit or receive any information from
        any person under the age of 18. If you are under the age of 18, do not provide us with any information about yourself, including your name, address, email address,
        telephone number, etc. If we learn we have collected or received Personal Information from anyone under the age of 18 without parental consent, we will promptly delete that
        information. If you believe we might have any Personal Information from or about anyone under the age of 18, please contact us&nbsp;
        <ButtonText to={CONST.LINKS.MAIN.CONTACT} newWindow selected>
          here
        </ButtonText>
        .
      </Paragraph>

      <Title>ABILITY TO UPDATE OR CORRECT YOUR PERSONALLY IDENTIFIABLE INFORMATION</Title>

      <Paragraph>
        You can update or correct the contact and loan application information that you&apos;ve given us, at any time. You may review and update information stored in your
        application online until you submit your application. Once you have submitted your application, you may only change the information by directly contacting our operations
        team at 1-800-550-6602 or emailing us at&nbsp;
        <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.CHANGEINFO}`} selected>
          {CONST.LINKS.EXTERNAL.EMAIL.CHANGEINFO}
        </ButtonText>
        .
      </Paragraph>
      <Paragraph>
        Beeline recommends that you do not use email communications to send us sensitive Personal Information. If you need to change that Personal Information, you should call us
        directly.
      </Paragraph>
      <Paragraph>
        You may have rights in non-U.S. countries, such as objecting to, and blocking the processing of your Personal Information. For security reasons, Beeline will take steps to
        authenticate your identity before providing you with access to Personal Information.
      </Paragraph>

      <Title>ACCESSIBILITY</Title>

      <Paragraph>
        Beeline is dedicated to making this Notice accessible to everyone, including individuals with disabilities. If you need to access this Notice in an alternative format,
        please contact&nbsp;
        <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.DEFAULT}`} selected>
          {CONST.LINKS.EXTERNAL.EMAIL.DEFAULT}
        </ButtonText>
        &nbsp;or reach us by phone at&nbsp;
        <ButtonText to={`tel:${CONST.LINKS.EXTERNAL.PHONE.DEFAULT}`} selected>
          {CONST.LINKS.EXTERNAL.PHONE.DASHED_INT}
        </ButtonText>
        .
      </Paragraph>

      <Title>WAYS TO CONTACT US ABOUT SECURITY AND PRIVACY.</Title>

      <Paragraph>
        Questions regarding our website&apos;s privacy statement, our online Privacy and Security Notice, our information practices or other aspects of privacy on our websites,
        should be directed to us at&nbsp;
        <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}`} selected>
          {CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}
        </ButtonText>
        &nbsp;or in writing to Beeline Loans, Inc., Attn: Privacy Officer, 188 Valley Street, Suite 225, Providence, RI 02909. You may also call us at&nbsp;
        <ButtonText to={`tel:${CONST.LINKS.EXTERNAL.PHONE.DEFAULT}`} selected>
          {CONST.LINKS.EXTERNAL.PHONE.DASHED_INT}
        </ButtonText>
        .
      </Paragraph>
    </Container>
  </NavOnlyTemplate>
);

export default PrivacyAndSecurity;
